import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Ffpdf from "src/components/ffpdf"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Disclaimers from "@tightrope/disclaimers"
import ChunkyFooter from "src/components/chunkyfooter"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const footerData = import("./data/footerData.json");

const ffpdfData = import("./data/data.json");

export default function spbFl2a2Print() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
     .footerlinks-module--br {
      display:none !important;
     }
       

       #chunkyfooter-module--chunkyFooter .chunkyfooter-module--footerButton {
        background-color: #0078ff;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 15px 50px;
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        margin: 20px 0;
       }

       #chunkyfooter-module--chunkyFooter .chunkyfooter-module--footerButton:hover {
        background-color: #0066dd;
        transform: scale(1.05);
       }

       #disclaimers-module--disclaimers {
        width:100%;
       }

       #disclaimers-module--disclaimers p {
        font-weight: 300;
        color: #333;
        font-size: 13px;
        text-align: center;
        font-family: Roboto,sans-serif;
        max-width: 580px;
        margin: 0 auto;
        }
  
        #disclaimers-module--disclaimers p a {
        font-weight:700;
        }
       footer {
        background:#fff;
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: relative;
        color: #333;
        border-radius: 5px;
        width: 100%;
      }
      footer ul {
        position: relative;
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: right;
        color: #333;
        font-size: 12px;
        padding: 12px 0;
      }
      footer ul li {
        padding: 0 4px 0 0;
      }
      footer ul li:last-child {
        padding: 0;
      }
      footer ul li:after {
        content: '|';
        padding: 0 0 0 4px;
      }
      footer ul li:nth-child(6):after, footer ul li:nth-child(7):after, footer ul li:last-child:after {
        content: none;
        padding: 0;
      }
      footer ul a {
        color: #333;
      }
      @media only screen and (max-width: 768px) {
    
        footer {
          position: relative;
          display: flex;
          justify-content:center;
          align-items:center;
          color: #333;
          width: 100%;
          right:auto;
          bottom:auto;
      }
    
      footer ul {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Ffpdf data={ffpdfData}>
      <Disclaimers language='en-firefox'></Disclaimers>
      </Ffpdf>
      <ChunkyFooter data={footerData}><Disclaimers language='en-firefox'></Disclaimers></ChunkyFooter>
      </section>
      <RawFooter></RawFooter>
    </HomepageLayout>
  )
}
