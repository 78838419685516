import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/ffpdf.module.scss"
import Data from "./data/pdfdata.json"
import Footerlinks from "@tightrope/footerlinks/footer"

class ffpdf extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  
  render() {
    return (
      <>
      <div id={Styles.ffpdf}>
        <div className={Styles.header}>
          <img src={this.state.data.prodLogo} alt="Easy Print Logo"/>
        </div>
        <div className={Styles.main}>
        <h1 className = "hc_param">{this.state.data.headline}<span className={Styles.prodIco}><img src={this.state.data.prodIco} alt="Printer"/></span></h1>
          
          <div className={Styles.mainContent}>
            <p>{this.state.data.copy1}</p>
            <button 
              className={`${Styles.download} cl1`} 
              data-cy="cta" 
              onClick={() => window.triggerInstall()}
            >
              {this.state.data.button}
            </button>
          </div>
          
          
          
          {this.props.children}
        </div>
      </div>
      <Footerlinks></Footerlinks>
      </>
    )
  }
}

export default ffpdf
